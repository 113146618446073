<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar :title="huiyuan.name" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right></template>
    </van-nav-bar>
    <div  style="padding-top:46px;background: #f5f5f5;">
      <img class="huiyuan_img" v-if="huiyuan.activityUrl" :src="huiyuan.activityUrl" alt />
      <img class="huiyuan_img" v-else src="../../assets/img/chaozhi.png" alt />
      <div style="height: 52px;"></div>
    </div>
    <div style="height: 54px;"></div>
    <div class="bott">
      <div class="bott_c" @click="goumai">
        <span class="huodjia">￥{{huiyuan.activityPrice.toFixed(2)}}</span>
        <del class="zhenjia">￥{{huiyuan.sellPrice.toFixed(2)}}</del>
        <span class="kaitong">立即开通</span>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      huiyuan: JSON.parse(sessionStorage.getItem("huiyuan")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: {},
      type: this.$route.query.type,
      page: 0,
      size: 10,
      zhehou: 100,
      zongjia: 120,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    goumai() {
      this.$router.push({
        path: "ka_pay",
        query: {
          isok: true
        }
      });
    },
    onLoads() {},
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("Agid")
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: [
                "getLocation",
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareAppMessage"
              ]
            });
            wx.ready(() => {
              wx.onMenuShareAppMessage({
                title: this.shangjia.name,
                desc: "邀请您使用驿车驹在线服务！",
                link:
                  "https://shopping.car-posthouse.cn/member_details?gid=" +
                  sessionStorage.getItem("Agid") +
                  "&cid=" +
                  this.userInfo.customerId +
                  "&appid=" +
                  sessionStorage.getItem("appid"),
                imgUrl:
                  "https://shopping.car-posthouse.cn/assets/img/fuwu1.2584c349.png",
                success: function() {}
              });
            });
            wx.ready(() => {
              wx.getLocation({
                type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function(res) {
                  console.log(res);
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  localStorage.setItem("JD", res.longitude);
                  localStorage.setItem("WD", res.latitude);
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                }
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    }
  },
  created() {
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.huiyuan_img {
  width: 100%;
}

// 底部
.bott {
  height: 55px;
  width: 100%;
  background: #131818;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_c {
  overflow: hidden;
  margin-top: 10px;
  font-size: 14px;
  background: #e4d2a8;
  color: #000;
  //   font-weight: bold;
  padding: 7px 50px;
  border-radius: 19px;
}
.huodjia {
  color: red;
  font-size: 16px;
}
.zhenjia {
  font-size: 12px;
  color: #666;
  margin-left: 3px;
}
.kaitong {
  font-weight: bold;
  margin-left: 50px;
}
.taocan_jindu {
  height: 40px;
  width: 90%;
  margin: auto;
  display: block;
}
.jindu_name {
  font-size: 12px;
  line-height: 16px;
}
.taocan_benjin {
  background-image: url(../../assets/img/taocan_img.png);
  width: 96%;
  height: 190px;
  margin: auto;
  margin-top: 20px;
  background-size: 100% 100%;
  font-size: 12px;
  color: #fff;
  position: relative;
}
.taocan_name {
  font-size: 16px;
  margin-left: 27px;
  padding-top: 15px;
}
.taocan_name_b {
  margin-left: 20px;
  line-height: 18px;
}
.taocan_jia {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 45px;
  margin-bottom: 8px;
  margin-left: 20px;
}
.taocan_shen {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 15px;
}
.chaozhi {
  font-size: 12px;
  color: #666;
  margin-top: 7px;
  padding-bottom: 8px;
  border-bottom: 8px solid #f5f5f5;
}
.tanan_xq {
  line-height: 34px;
  color: #999;
  font-size: 12px;
  border-bottom: 1px solid #f5f5f5;
  margin-left: 12px;
}
.tao_gou {
  width: 17px;
}
.taocan_list {
  overflow: hidden;
  padding: 12px 15px 0px 12px;
}
.list_a {
  font-size: 14px;
  overflow: hidden;
}
.list {
  border-bottom: 8px solid #f5f5f5;
}
.list_b {
  font-size: 12px;
  color: #666;
  margin-top: 8px;
  border-bottom: 1px dotted #f5f5f5;
  padding-bottom: 6px;
}
.tao_che {
  width: 22px;
  margin-top: 1px;
}
.shuoming {
  font-size: 12px;
  padding: 12px 12px 0 12px;
  color: #666;
}
.shuoming p {
  line-height: 20px;
}
// 底部
.bott_tao {
  height: 50px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 0 12px;
}
.bott_ta {
  margin-top: 10px;
  font-size: 14px;
  background: #df3447;
  color: #fff;
  font-weight: bold;
  line-height: 30px;
  height: 30px;
  padding: 0px 25px;
  border-radius: 19px;
}
.kefu {
  width: 25px;
  height: 25px;
}
.bott_a {
  text-align: center;
  margin-top: 5px;
}
.bott_b {
  font-size: 13px;
  margin-right: 10px;
  margin-top: 5px;
}
.zaixian {
  font-size: 12px;
  line-height: 12px;
}
.danwei {
  font-size: 12px;
  color: #666;
}
.van-icon {
  font-size: 20px;
}
.jin_biao {
  font-size: 14px;
}
.monede {
  font-size: 22px;
  font-weight: bold;
  color: #d9314c;
}
.zhe {
  color: #666;
  margin-left: 5px;
}
.zj {
  line-height: 20px;
}
.yj {
  line-height: 20px;
}
//
</style>